<template>
    <div class="container">
        <div class="fl-x-cc pb-4">
            <h4 class="text-seventh"> Diagnose</h4>
        </div>
        <div class="row mt-4">
            <div class="col-lg-3 col-6">
                <div @click="redirectToIndividualPage( catIds.horse)">
                    <img src="../../../assets/img/elemn2/horse.png" class="img-fluid h-4r">
                    <p class="font-proxima-bold ml-3 text-secondary">Horse</p>
                </div>
            </div>
            <div class="col-lg-3 col-6">
                <div @click="redirectToIndividualPage( catIds['goat and sheep'])">
                    <img src="../../../assets/img/elemn2/goat.png" class="img-fluid h-4r">
                    <p class="font-proxima-bold ml-3 text-secondary">Goat</p>
                </div>
            </div>

            <div class="col-lg-3 col-6">
                <div @click="redirectToIndividualPage( catIds.pigs)">
                    <img src="../../../assets/img/elemn2/pig.png" class="img-fluid h-4r">
                    <p class="font-proxima-bold ml-3 text-secondary">Pig</p>
                </div>
            </div>
            <div class="col-lg-3 col-6">
                <div @click="redirectToIndividualPage( catIds['poultry'])">
                    <img src="../../../assets/img/elemn2/poultry.png" class="img-fluid h-4r">
                    <p class="font-proxima-bold ml-3 text-secondary">Poultry</p>
                </div>
            </div>
            <!--            <div class="col-lg-3 pt-3">-->
            <!--                <div @click="redirectToIndividualPage( catIds['goat and sheep'])">-->
            <!--                    <img src="../../../assets/img/rabbit.png" class="img-fluid h-4r">-->
            <!--                    <p class="font-proxima-bold ml-3 text-secondary">Rabbit</p>-->
            <!--                </div>-->
            <!--            </div>-->
            <div class="col-lg-3 col-6 pt-3">
                <div @click="redirectToIndividualPage( catIds['cattle'])">
                    <img src="../../../assets/img/elemn2/cow.png" class="img-fluid h-4r">
                    <p class="font-proxima-bold ml-3 text-secondary">Cattle</p>
                </div>
            </div>
            <div class="col-lg-3 col-6 pt-3">
                <div @click="redirectToIndividualPage( catIds['cats'])">
                    <img src="../../../assets/img/elemn2/cat.png" class="img-fluid h-4r">
                    <p class="font-proxima-bold ml-3 text-secondary">Cats</p>
                </div>
            </div>
            <div class="col-lg-3 col-6 pt-3">
                <div @click="redirectToIndividualPage( catIds['dogs'])">
                    <img src="../../../assets/img/elemn2/dog.png" class="img-fluid h-4r">
                    <p class="font-proxima-bold ml-3 text-secondary">Dogs</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'UserHomeDiagnoseList',
    components: {},
    data () {
        return {
            bannersUrl: urls.userHome.banners.list,
            banners: '',
            catIds: ''
        };
    },
    mounted () {
        this.LoadData();
    },
    methods: {
        async LoadData () {
            console.log('LoadData');
            const response = await axios.get(this.bannersUrl);
            const result = await response.data;
            this.banners = result.ads;
            this.catIds = result.cat_ids;
        },
        redirectToIndividualPage (idx) {
            this.$router.push('/individual-species/' + idx);
        }
    }
};
</script>

<style scoped>

</style>
